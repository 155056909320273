
























































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  getRemoteProperties,
  getRemoteClients,
  getRemoteUsers,
  getPropertiesLight,
  getClientsLight,
  getUsersLight
} from '@/api/helpers'
import { getTranslations, hasPermission } from '@/utils'
import { Form } from 'element-ui'
import { Property } from '@/models/Property'
import { Client } from '@/models/Client'
import { UserModule } from '@/store/modules/user'
import { createFilter, FilterOperator, FilterType } from '@/utils/filter'

@Component({
  name: 'VisitForm'
})

export default class extends Vue {
  @Prop({ default: 0 }) position! : number
  @Prop({ required: true }) visit : any
  $refs!: {
    visitForm: Form
  }

  private propertiesLoading = false
  private clientsLoading = false
  private usersLoading = false
  private getTranslations = getTranslations
  private properties: Property[] = []
  private clients: Client[] = []
  private users: any = []
  private rules = {
    client_id: [{
      required: true,
      message: this.$t('form.isRequired'),
      trigger: 'blur'
    }],
    user_id: [{
      required: true,
      message: this.$t('form.isRequired'),
      trigger: 'blur'
    }]
  }

  get canInsertForOthers() {
    return hasPermission(['EDIT_OTHERS_VISITS'])
  }

  async created() {
    if (this.visit.property) {
      this.properties.push(this.visit.property)
    } else if (this.visit.property_id) {
      const { data } = await getPropertiesLight(createFilter([{
        type: FilterType.field,
        key: 'id',
        value: this.visit.property_id,
        operator: FilterOperator.eq
      }]))
      this.properties.push(...data.collection)
    }
    if (this.visit.client) {
      this.clients.push(this.visit.client)
    } else if (this.visit.client_id) {
      const { data } = await getClientsLight(createFilter([{
        type: FilterType.field,
        key: 'id',
        value: this.visit.client_id,
        operator: FilterOperator.eq
      }]))
      this.clients.push(...data.collection)
    }
    if (this.visit.user) {
      this.users.push(this.visit.user)
    } else if (this.visit.user_id) {
      const { data } = await getUsersLight(createFilter([{
        type: FilterType.field,
        key: 'id',
        value: this.visit.user_id,
        operator: FilterOperator.eq
      }]))
      this.users.push(...data.collection)
    } else if (!this.visit.id) {
      this.users = [UserModule.user]
    }
  }

  private async getRemotePropertiesList(query: string) {
    this.propertiesLoading = true
    this.properties = await getRemoteProperties(query)
    this.propertiesLoading = false
  }

  private async getRemoteClientsList(query: string) {
    this.clientsLoading = true
    this.clients = await getRemoteClients(query)
    this.clientsLoading = false
  }

  private async getRemoteUsersList(query: string) {
    this.usersLoading = true
    this.users = await getRemoteUsers(query)
    this.usersLoading = false
  }
}
