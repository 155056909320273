








































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  deleteVisit,
  getVisit,
  saveVisit,
  saveVisitBulk
} from '@/api/visits'
import Sticky from '@/components/Sticky/index.vue'
import VisitForm from './VisitForm.vue'
import { UserModule } from '@/store/modules/user'
import { AppModule, DeviceType } from '@/store/modules/app'
import { Visit } from '@/models/Visit'
import { confirmDialog, errorMsg, successMsg, validateForm } from '@/utils'

@Component({
  name: 'VisitDetail',
  components: {
    Sticky,
    VisitForm
  }
})

export default class extends Vue {
  @Prop({ default: false }) private isEdit!: boolean
  $refs!: {
    visits: []
  }

  private loading = false
  private skeletonLoader = false
  private activeNames: number[] = []
  private visits: Visit[] = []
  private visit: Visit = new Visit({
    owner: UserModule.id,
    user_id: UserModule.id,
    office_id: UserModule.officeId,
    completion_date: new Date()
  })

  created() {
    if (this.isEdit) {
      this.getItem(this.$route.params.id)
    } else {
      this.addNewVisit()
    }
  }

  get isMobile() {
    return AppModule.device === DeviceType.Mobile
  }

  private async getItem(id: string) {
    this.skeletonLoader = true
    try {
      const { data } = await getVisit({ id })
      this.visit = data
    } catch (err) {}
    this.skeletonLoader = false
  }

  private addNewVisit(isClone = false) {
    const lastVisit = this.visits[this.visits.length - 1]
    let visit = new Visit({
      owner: UserModule.id,
      user_id: UserModule.id,
      office_id: UserModule.officeId,
      completion_date: new Date()
    })
    if (isClone && lastVisit) {
      visit = JSON.parse(JSON.stringify(lastVisit))
    }
    this.visits.push(visit)
    this.activeNames = [this.visits.length - 1]
  }

  private async addVisit(isClone = false) {
    const lastIndex = this.visits.length - 1
    try {
      const childComp = this.$refs.visits[lastIndex] as any
      await childComp.$refs.visitForm.validate()
      this.addNewVisit(isClone)
    } catch (e) {
      this.activeNames = [lastIndex]
      await errorMsg('form.formErrors')
    }
  }

  private async deleteItem() {
    const [data] = await confirmDialog('visitList.delete')
    if (!data) {
      return
    }
    try {
      await deleteVisit({
        id: this.visit.id
      })
      await successMsg('visitList.deleteSuccess')
      return this.$router.push('/visit/list')
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private removeVisit(index: number) {
    this.visits.splice(index, 1)
  }

  private prepareVisit(visit: any) {
    visit.owner = visit.user_id || visit.owner
    visit.user = null
    visit.property = null
    visit.completion_date = new Date(visit.completion_date).getTime()
  }

  private async saveItemBulk() {
    const childComp = this.$refs.visits[this.visits.length - 1] as any
    if (!childComp) {
      return errorMsg('form.formErrors')
    }
    const [data] = await validateForm(childComp.$refs.visitForm)
    if (!data) {
      this.activeNames = [this.visits.length - 1]
      return errorMsg('form.formErrors')
    }
    this.loading = true
    try {
      this.visits.forEach(this.prepareVisit)
      await saveVisitBulk(this.visits)
      return this.$router.push('/visit/list')
    } catch (err) {
      await errorMsg('api.serverError')
    }
    this.loading = false
  }

  private async saveItem() {
    this.loading = true
    try {
      this.prepareVisit(this.visit)
      await saveVisit(this.visit)
      await successMsg('actions.apiSuccessSave')
    } catch (err) {
      await errorMsg('api.serverError')
    }
    this.loading = false
  }
}
